export const lightTheme = {
  colors: {
    primary: '#4ecdc4',
    background: '#ffffff',
    text: '#2d3436',
    textSecondary: '#636e72',
    card: '#f5f6fa',
    border: '#dfe6e9',
  },
};

export const darkTheme = {
  colors: {
    primary: '#3498db',
    background: '#0f172a', // Rich navy blue background
    text: '#e2e8f0', // Soft white text
    textSecondary: '#94a3b8', // Muted blue-gray text
    card: '#1e293b', // Slightly lighter navy for cards
    border: '#334155', // Subtle border color
  },
};

export const theme = darkTheme; // Set blue theme as default 