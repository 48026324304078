import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaBriefcase } from 'react-icons/fa';

const ExperienceSection = styled.section`
  padding: 100px 0;
  background: ${({ theme }) => theme.colors.background};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Timeline = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 768px) {
    &::before {
      left: 20px;
    }
  }
`;

const TimelineItem = styled(motion.div)`
  position: relative;
  margin-bottom: 50px;
  width: 100%;
  padding: ${({ isLeft }) => (isLeft ? '0 50% 0 0' : '0 0 0 50%')};

  @media (max-width: 768px) {
    padding: 0 0 0 50px;
  }
`;

const TimelineContent = styled.div`
  background: ${({ theme }) => theme.colors.card};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    ${({ isLeft }) => (isLeft ? 'right: -10px' : 'left: -10px')};
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    &::before {
      left: -35px;
    }
  }
`;

const TimelineDate = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.9rem;
  margin-bottom: 5px;
`;

const TimelineTitle = styled.h3`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.2rem;
  margin-bottom: 5px;
`;

const TimelineCompany = styled.h4`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 1rem;
  margin-bottom: 10px;
`;

const TimelineDescription = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 0.9rem;
  line-height: 1.6;
`;

const TechStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 15px;
`;

const TechTag = styled.span`
  background: ${({ theme }) => theme.colors.primary}20;
  color: ${({ theme }) => theme.colors.primary};
  padding: 4px 8px;
  border-radius: 15px;
  font-size: 0.8rem;
`;

const Experience = () => {
  const experiences = [
    {
      title: "Software Engineer",
      company: "Bidgely",
      date: "2023 - Present",
      description: "Working on automation project using Python, Flask, React, and PostgreSQL. Developing and maintaining web applications with modern tech stack.",
      tech: ["Python", "Flask", "React", "PostgreSQL"],
      isLeft: true
    },
    {
      title: "Software Engineer",
      company: "Principal Global Investor",
      date: "2022 - 2023",
      description: "Worked on enterprise applications using Spring Boot, Java, Hibernate, .NET, and AWS. Implemented CI/CD pipelines and cloud infrastructure.",
      tech: ["Java", "Spring Boot", ".NET", "AWS", "CI/CD"],
      isLeft: false
    },
    {
      title: "SDE Intern",
      company: "Scaler",
      date: "2021 - 2022",
      description: "Developed web applications using Ruby on Rails and React.js. Worked with SQL databases and implemented various features.",
      tech: ["Ruby on Rails", "React", "SQL"],
      isLeft: true
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <ExperienceSection id="experience">
      <Container>
        <SectionTitle
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          Experience
        </SectionTitle>
        <Timeline
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {experiences.map((experience, index) => (
            <TimelineItem
              key={index}
              variants={itemVariants}
              isLeft={experience.isLeft}
            >
              <TimelineContent isLeft={experience.isLeft}>
                <TimelineDate>{experience.date}</TimelineDate>
                <TimelineTitle>{experience.title}</TimelineTitle>
                <TimelineCompany>{experience.company}</TimelineCompany>
                <TimelineDescription>{experience.description}</TimelineDescription>
                <TechStack>
                  {experience.tech.map((tech, techIndex) => (
                    <TechTag key={techIndex}>{tech}</TechTag>
                  ))}
                </TechStack>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Container>
    </ExperienceSection>
  );
};

export default Experience; 