import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaEnvelope, FaDownload } from 'react-icons/fa';

const HeroSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 50px;
  background: ${({ theme }) => theme.colors.background};
  position: relative;
  overflow: hidden;
`;

const HeroContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const Greeting = styled(motion.h1)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
`;

const Name = styled(motion.h2)`
  font-size: 80px;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 60px;
  }
`;

const Title = styled(motion.h3)`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const Description = styled(motion.p)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.textSecondary};
  max-width: 600px;
  margin-bottom: 30px;
  line-height: 1.6;
`;

const SocialLinks = styled(motion.div)`
  display: flex;
  gap: 20px;
`;

const SocialLink = styled(motion.a)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 24px;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ButtonContainer = styled(motion.div)`
  display: flex;
  gap: 20px;
  margin-top: 30px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
`;

const CTAButton = styled(motion.a)`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 12px 30px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
  text-decoration: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
`;

const DownloadButton = styled(CTAButton)`
  background: transparent;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.background};
  }
`;

const Hero = () => {
  const greetingWords = "Hi, my name is".split(" ");
  const nameWords = "Md Irfan.".split(" ");
  const descriptionWords = "I'm a software engineer specializing in building exceptional digital experiences. Currently, I'm focused on building accessible, human-centered products.".split(" ");

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const wordVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <HeroSection id="home">
      <HeroContent>
        <Greeting
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {greetingWords.map((word, index) => (
            <motion.span key={index} variants={wordVariants}>
              {word}{" "}
            </motion.span>
          ))}
        </Greeting>
        <Name
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {nameWords.map((word, index) => (
            <motion.span key={index} variants={wordVariants}>
              {word}{" "}
            </motion.span>
          ))}
        </Name>
        <Title
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          I build things for the web.
        </Title>
        <Description
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {descriptionWords.map((word, index) => (
            <motion.span key={index} variants={wordVariants}>
              {word}{" "}
            </motion.span>
          ))}
        </Description>
        <SocialLinks
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <SocialLink
            href="https://github.com/mdirfan121"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaGithub />
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/in/md-irfan-2021/"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaLinkedin />
          </SocialLink>
          <SocialLink
            href="mailto:irfandev356@gmail.com"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaEnvelope />
          </SocialLink>
        </SocialLinks>
        <ButtonContainer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <CTAButton
            href="#contact"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Get In Touch
          </CTAButton>
          <DownloadButton
            href="https://drive.google.com/file/d/16Xw4yqtSxaRFmGN8bsvwdcU9oKsPu8Vm/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaDownload size={18} />
            Download Resume
          </DownloadButton>
        </ButtonContainer>
      </HeroContent>
    </HeroSection>
  );
};

export default Hero; 