import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCode, FaDatabase, FaChartLine, FaServer, FaMobile, FaTools } from 'react-icons/fa';

const SkillsSection = styled.section`
  padding: 100px 0;
  background: ${({ theme }) => theme.colors.background};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 50px;
  text-align: center;
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
`;

const SkillCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.card};
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      transparent,
      ${({ theme }) => theme.colors.primary}10,
      transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }

  &:hover {
    transform: translateY(-5px);
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);

    &::before {
      transform: translateX(100%);
    }
  }
`;

const SkillIcon = styled.div`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 15px;
  transition: transform 0.3s ease;

  ${SkillCard}:hover & {
    transform: scale(1.1);
  }
`;

const SkillTitle = styled.h3`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 10px;
  transition: color 0.3s ease;

  ${SkillCard}:hover & {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const SkillDescription = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 0.9rem;
  line-height: 1.6;
  transition: color 0.3s ease;

  ${SkillCard}:hover & {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const Skills = () => {
  const skillCategories = [
    {
      icon: <FaCode />,
      title: "Frontend Development",
      skills: [
        "React.js",
        "HTML5 & CSS3",
        "JavaScript (ES6+)",
        "Responsive Design",
        "UI/UX Principles"
      ]
    },
    {
      icon: <FaServer />,
      title: "Backend Development",
      skills: [
        "Java",
        "Spring Boot",
        "Python",
        "Ruby on Rails",
        "RESTful APIs"
      ]
    },
    {
      icon: <FaDatabase />,
      title: "Databases",
      skills: [
        "SQL",
        "PostgreSQL",
        "MongoDB",
        "Database Design",
        "Query Optimization"
      ]
    },
    {
      icon: <FaChartLine />,
      title: "Data Analysis",
      skills: [
        "Data Cleaning",
        "KPI Analysis",
        "Data Visualization",
        "Seaborn",
        "Matplotlib"
      ]
    },
    {
      icon: <FaTools />,
      title: "Tools & Technologies",
      skills: [
        "Git & GitHub",
        "Docker",
        "AWS",
        "CI/CD",
        "Agile Methodologies"
      ]
    },
    {
      icon: <FaMobile />,
      title: "Additional Skills",
      skills: [
        "Problem Solving",
        "Team Leadership",
        "Code Review",
        "Technical Documentation",
        "Performance Optimization"
      ]
    }
  ];

  return (
    <SkillsSection id="skills">
      <Container>
        <SectionTitle
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          Skills & Expertise
        </SectionTitle>
        <SkillsGrid>
          {skillCategories.map((category, index) => (
            <SkillCard
              key={category.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.02 }}
            >
              <SkillIcon>{category.icon}</SkillIcon>
              <SkillTitle>{category.title}</SkillTitle>
              <SkillDescription>
                {category.skills.map((skill, skillIndex) => (
                  <div key={skillIndex}>{skill}</div>
                ))}
              </SkillDescription>
            </SkillCard>
          ))}
        </SkillsGrid>
      </Container>
    </SkillsSection>
  );
};

export default Skills; 