import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';

const AboutSection = styled.section`
  padding: 100px 0;
  background: ${({ theme }) => theme.colors.background};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at 20% 20%,
      ${({ theme }) => theme.colors.primary}10,
      transparent 50%
    );
    pointer-events: none;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 50px;
  text-align: center;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 2px;
  }
`;

const AboutContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const AboutText = styled(motion.div)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;

  p {
    margin-bottom: 1.5rem;
  }
`;

const AboutCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.card};
  padding: 30px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      transparent,
      ${({ theme }) => theme.colors.primary}10,
      transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }

  &:hover {
    transform: translateY(-5px);
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);

    &::before {
      transform: translateX(100%);
    }
  }
`;

const AboutImage = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const SocialLinks = styled(motion.div)`
  display: flex;
  gap: 20px;
  margin-top: 30px;
  justify-content: flex-start;
`;

const SocialLink = styled(motion.a)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.5rem;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.card};
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transform: translateY(-3px);
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

const About = () => {
  return (
    <AboutSection id="about">
      <Container>
        <SectionTitle
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          About Me
        </SectionTitle>
        <AboutContent>
          <AboutText
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <p>
              I am a passionate software engineer with expertise in full-stack development. My journey in technology has been driven by a deep curiosity for creating efficient and scalable solutions. I specialize in Java, Python, and web development, with a strong foundation in data analysis and machine learning.
            </p>
            <p>
              My technical skills include Java, Python, Spring Boot, Hibernate, React, and various databases. I have experience in Exploratory Data Analysis (EDA), building RESTful APIs, and implementing modern web applications. I'm always eager to learn new technologies and solve complex problems.
            </p>
            <SocialLinks
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <SocialLink
                href="https://github.com/mdirfan121"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
              >
                <FaGithub />
              </SocialLink>
              <SocialLink
                href="https://www.linkedin.com/in/md-irfan-2021/"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
              >
                <FaLinkedin />
              </SocialLink>
              <SocialLink
                href="mailto:irfandev356@gmail.com"
                whileHover={{ scale: 1.1 }}
              >
                <FaEnvelope />
              </SocialLink>
            </SocialLinks>
          </AboutText>
          <AboutCard
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <AboutImage>
              <img 
                src="/images/profile_image.jpeg" 
                alt="Profile" 
              />
            </AboutImage>
          </AboutCard>
        </AboutContent>
      </Container>
    </AboutSection>
  );
};

export default About; 